@font-face {
    font-family: 'SFSportsNight'; 
    src: url('/public/fonts/SFSportsNight.ttf') format('truetype');  
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'TeamSpiritNF'; 
    src: url('/public/fonts/TeamSpiritNF.ttf') format('truetype');  
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Dattermatter Personal Use'; 
    src: url('/public/fonts/Dattermatter\ Personal\ Use.ttf') format('truetype');  
    font-weight: normal;
    font-style: normal;
}
@font-face {
   font-family: 'Soccer Scoreboard'; 
   src: url('/public/fonts/Soccer\ Scoreboard.otf') format('truetype');  
   font-weight: normal;
   font-style: normal;
}
@font-face {
   font-family: 'Butterfly'; 
   src: url('/public/fonts/Butterfly.ttf') format('truetype');  
   font-weight: normal;
   font-style: normal;
}
@font-face {
   font-family: 'Modern Age'; 
   src: url('/public/fonts/Modern\ Age.otf') format('truetype');  
   font-weight: normal;
   font-style: normal;
}
@font-face {
   font-family: 'Moodern'; 
   src: url('/public/fonts/MOODERN.ttf') format('truetype');  
   font-weight: normal;
   font-style: normal;
}

.video-container {
    position: relative;
    width: 100vw;       
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; 
}
 
 .video-container video {
    width: 100%;   
    height: 80vh;
    object-fit: cover;
 }

 .text-container {
    position: absolute;
    top: 50%;
    left: 150px;
    transform: translateY(-50%);
    color: white;
    z-index: 2;
    text-align: left;
    width: 500px;
 }

 .text-container-landing {
  position: absolute;
  top: 50%;
  left: 150px;
  transform: translateY(-50%);
  color: white;
  z-index: 2;
  text-align: left;
  width: 80%;
}
.landing-tag{
  text-align: center;
  color: aliceblue;
  font-size: 40px;
}
 
 .video-title {
    font-family: 'SFSportsNight';
    font-size: 3rem;
    margin: 0;
 }

 .nugs{
    font-family: 'SFSportsNight';
 }
 .champions{
    font-family: 'SFSportsNight';
 }
 .hoops{
    font-family: 'TeamSpiritNF';
    font-size: 5rem;
    text-shadow: 
    0px 0px 0 black,  
    4px 0px 0 black,
    0px  4px 0 black,
    4px  4px 0 black;
 }
 .golf{
    font-family: 'RubabyGamePersonal';
 }
 .tennis{
   font-family: 'Moodern'; 
 }
 .soccer{
   font-family: 'Soccer Scoreboard';
 }
 .jazz{
   font-family: 'Butterfly';
   font-size: 3rem; 
 }
 .hex{
   font-family: 'Modern Age';
 }
 
 .video-description {
    font-size: 1rem;
    margin: 5px 0 0;
    text-shadow: 1px black;
 }
 .overlay {
    position: absolute;
    top: -100;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0,0,0,1) 1%, transparent 28%);
    z-index: 1;
 }

 .overlay-tile-carousel{
  position: absolute; 
  transform: translateY(-350px);
  height: 400px;
  width: 100vw;
  z-index: 40; 
  pointer-events: none; 
  background: radial-gradient(circle, rgba(96,136,44,0) 52%, rgba(0,0,0,0.9472163865546218) 100%);
 }

 .slick-dots li button {
    background-color: gray;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transition: background-color 0.3s;
 }
 

 .slick-dots li button:hover,
 .slick-dots li.slick-active button {
    background-color: white; 
 }
 .join-button {
  z-index: 3;
  padding: 10px 40px;
  background: rgb(42, 222, 138);
  background: linear-gradient(270deg, rgb(42, 222, 138) 1%, rgb(82, 199, 226) 64%);
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-size: large;
  font-weight: bold;
  color: white;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 8px 15px rgba(43, 255, 0, 0.2);
}

.join-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
}

.join-button:active {
  transform: translateY(0);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}


 .about-section {
   display: flex;
   flex-direction: column;
   color: white;
   text-align: left;
   width: 90%;
   margin: 50px auto;
   border: 1px solid gray;
   padding: 20px;
   border-radius: 15px;
   background: rgb(96, 136, 44);
   background: radial-gradient(
     circle,
     rgba(96, 136, 44, 0.2) 0%,
     rgba(27, 78, 221, 0.2) 100%
   );   
 }
 .about-variation {
  color: white;
  text-align: left;
  width: 50%;
  margin: 40px auto;
  padding: 25px;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.about-sections-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px; 
  padding: 40px;
  margin: 0 auto;
  max-width: 1200px; 
}

.about-variation {
  flex: 1; 
  min-width: 45%; 
}

@media (max-width: 1024px) {
  .about-sections-container {
    flex-direction: column;
    align-items: center;
  }

  .about-variation {
    min-width: unset;
    width: 100%;
  }
}

 .content {
   display: flex;
   align-items: flex-start;
   gap: 20px; 
 }
 
 .content p {
   flex: 1;
   margin: 0;
 }
 
 .content img {
   height: 200px;
   width: 250px;
   border-radius: 15px;
   object-fit: cover;
   align-self: flex-start;
   transform: translateY(-50px);
 }

 .search-input {
   background-color: transparent;
   border: 2px solid white;
   border-radius: 25px;
   padding: 5px 15px;
   color: white;
   font-size: 1rem;
   outline: none;
   width: 200px;
   transition: border-color 0.3s;
 }
 
 .search-input::placeholder {
   color: rgba(255, 255, 255, 0.7);
 }
 
 .search-input:focus {
   border-color: #00aaff; 
 }
 
 .online-tracker{
   display: flex;
   align-items: center;
   position: absolute;
   top: 0;
   left:10px;
   margin-top: 3px;
   padding: 0px 10px;
   background-color: rgba(34, 34, 37, 0.7);
   color: rgb(196, 193, 193);
   border-radius: 25px;
   font-size: 10px;
   z-index: 3;
 }
 .online-tracker p {
   margin: 0;
 }

 .tracker-text {
   display: flex;
   align-items: center;
   font-size: 0.6rem; 
 }
 
 .icon-container {
   display: flex;
   align-items: center;
   justify-content: center;
   transform: scale(0.5); 
   margin-right: 2px;
   color: lightgreen; 
 }
 .slider-container .slick-slide {
  transition: transform 0.3s ease-out;
}
.contact-button{
  z-index: 3;
  padding: 10px 20px;
  background: transparent;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-size: large;
  font-weight: bold;
  color: white;
}

.contact-button:hover{
  transform: scale(1.2);
}

/* Full page container to center the login container */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
}

.login-container {
  background-image: url('/public/images/nugs/audience.jpg'); 
  background-size: cover;
  background-position: center;
  width: 80%;
  height: 80%;
  border-radius: 25px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
}

.login-button {
  background-color: #007bff;
  border: none;
  color: white;
  width: 100%;
  margin: 0 auto;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #24b300;
}

.account-info {
  position: fixed;
  right: 10;
  color: white;
}
.login-elements-container{
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}