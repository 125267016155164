.chat{
  position: fixed;
  right: 50px;
  bottom: 1%;
  background-color: transparent;
  color: rgb(100,234,255);
  border-radius: 20px;
  font-family: Outfit;
}
.chat-header-container {
  margin-left: 20px;
  display: flex;
  align-items: center;
  gap: 10px; 
}
.chat-viewport-container{
  transform: translateX(-40px);
}

.can-click{
  cursor: pointer;
}

.chat-pill {
  padding: 6px 16px;
  background: linear-gradient(349deg, var(--primary-color) 30%, var(--highlight-color) 85%);
  color: white;
  border-radius: 25px; 
}

.studio-title {
  color: white; 
  font-weight: lighter;
  font-size: 30px;
}

.chat-body {
  width: 100%;
  display: block;
  position: relative;
  padding: 1.3rem;
  max-width: 800px;
  border-radius: 25px;
}

.chat-bubble-image,
.user-online-image{
  background: rgb(104,40,162);
  background: linear-gradient(349deg, var(--primary-color) 30%, var(--highlight-color) 85%);
}

.image-container {
  position: relative;
  right: 15px;
  width: 65px; 
  height: 65px; 
  border-radius: 50%;
}

.image-container::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 0.5px; 
  background: conic-gradient(var(--gradient-color, purple), transparent);
  -webkit-mask: 
    radial-gradient(farthest-side, transparent calc(100% - 4px), white calc(100% - 4px));
  mask: 
    radial-gradient(farthest-side, transparent calc(100% - 4px), white calc(100% - 4px));
  transform: rotate(180deg); 
  pointer-events: none; 
}

.outer-image-container{
  margin-bottom: 0;
  margin-top: 0;
}

.image-inner-container {
  position: relative;
  top: 6px; 
  left: 6px; 
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 50%;
  overflow: hidden;
}

.chat-bubble-image {
  width: 100%; 
  height: auto; 
  object-fit: cover;
  object-position: center top;
  transform: scale(2.2) translateY(20%); 
}

.image-container-online{
  position: relative;
  display: inline-block;
  width: 42px; 
  height: 42px; 
  margin: 2px;
}

.users-online-inner-image-container{
  position: absolute;
  top: 3px;
  left: 2px; 
  width: calc(100% -6px);
  height: calc(100% - 6px);
  border-radius: 50%;
  overflow: hidden;
  border: 2px var(--gradient-color) solid;
}
.user-online-image{
  display: block;
  width: 100%; 
  height: auto; 
  object-fit: cover; 
  object-position: center top; 
  transform: scale(2.2) translateY(20%); 
}


.chatText{
  color: rgb(100,234,255);
  text-align: center;
  font-family: Outfit;
}

.chat-container{
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: left;
  height: 30vh;
  margin: 0;
}
.chat-container::-webkit-scrollbar {
  width: 5px; 
}

.chat-container::-webkit-scrollbar-track {
  background-color: transparent; 
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: var(--highlight-color);
  border-radius: 5px; 
}

.chat-bubble-container{
  position: relative;
  right: 20px;
}

.chat-bubble{
  position: relative;
  left: 80px;
  top: -75px;
  text-decoration: none;
  text-align: left;
  width: 75%;
  margin-right: auto;
  border-radius: 5px;
  color: white;
  word-wrap: break-word; 
  white-space: normal;
}

.bubble-timestamp{
  margin-right: auto;
  color: white;
}

.user-chat-bubble{
  background-color:#baffc9;
  width: 80%;
  color: black;
  text-decoration: none;
  margin-right: auto;
  border-radius: 5px;
  word-wrap: break-word; 
  white-space: normal;
}
.user-bubble-timestamp{
  color: white;
  position: relative;
}
.reaction-div {
  position: absolute;
  right: 10px;
  bottom: -20px;
  background: linear-gradient(349deg, var(--primary-color) 30%, var(--highlight-color) 85%);
  padding: 5px;
  z-index: 10;
  border-radius: 20px;
  cursor: pointer;
  font-size: smaller;
}

.reactions-container{
  position: absolute;
  width: 20%;
  right: -12%;
  height: 100%;
  bottom: -20px;
}

.reactions{
  position: absolute !important;
  left: 50% !important;
  transform: translateY(-3px) !important;
  cursor: pointer!important;
  bottom: 30px !important;
}

.reactions-list{
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  padding: 5px;
  font-size: 25x;
}


.reactions-list-ul{
  padding-inline-start: 5px;
}
.chat-input{
  display: flex;
  align-items: center;
  margin: 1% auto;
  margin-top: 4%;
  width: 80%;
  z-index: 25000 !important;
  padding:0.2rem 0.2rem 0.2rem 1.2rem;
  border: 2px solid transparent;
  border-radius: 50px;
  background-color: rgba(0,19,53,0.5);
  position: relative;
  z-index: 2;
}

.chat-input::before{
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 25px;
  border: 2px solid transparent;
  background: linear-gradient(65deg, var(--primary-color), var(--secondary-color), var(--highlight-color), var(--error-color) 40%, var(--accent-color), var(--primary-color) 60%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
  z-index: 1;
}

#chat-input{
  border: none;
  background-color: transparent;
  width: 90%;
  height: 100%;
  color: white;
  font-size: larger;
}

#chat-input:focus {
  outline: none; 
}

.emoji-button {
  margin-left: 5px; 
  font-size: 240%;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: lightslategray;
}

.emoji-picker{
  position: absolute;
  bottom: 130%;
  right: 10%;
  z-index: 3500000;
}
.emoji-picker-reaction{
  position: absolute;
  bottom: 10%;
  right: 10%;
  z-index: 3500000;
}


.send-button{
  background: linear-gradient(79deg, var(--primary-color) 0%, var(--secondary-color) 55%, var(--accent-color) 63%, var(--error-color) 100%);
  border: none;
  color: black;
  border-radius: 50%;
  padding: 6px;
}

.is-typing-container{
  position: absolute;
  font-size: 12px;
  color: lightgray;
  bottom: 83px;
  left: 20px;
}

.dot-text-chat{
  position: absolute;
  left: 45px;
  top: 30px;
  font-size: 200%;
  margin: 0;
  padding: 0;;
}
.red-text-chat{
  color: red;
}
.green-text-chat{
  color: lime;
}


/* Wobble Animation */
@keyframes wobble {
  0%, 100% {
    transform: translateX(0);
  }
  15%, 45%, 75% {
    transform: translateX(-1px);
  }
  30%, 60%, 90% {
    transform: translateX(1px);
  }
}

.wobble {
  animation: wobble 0.5s ease-in-out;
}

.scroll-to-bottom {
  position: fixed; 
  bottom: 80px;
  right: 40%;
  background-color: transparent;
  border-radius: 25px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  border: 2px solid transparent;
  cursor: pointer;
  z-index: 1000;
}
.scroll-to-bottom:hover {
  background-color: rgba(3, 5, 29, 0.25);
  color: white;
}

.ellipsis{
  background-color: rgba(3, 5, 29, 0.25);
  border-radius: 50%;
  padding: 4px;
  position: relative;
  bottom: 1px;
  left: 2px;
}