.debug-panel{
    position: absolute;
    background-color: rgba(34, 36, 48, 0.7);
    border-radius: 25px;
    top: 2%;
    left: 10%;
    color: white;
    height: 300px;
    width: 600px;
    z-index: 350000;
    overflow: hidden;
}
.debug-panel::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 25px;
    border: 2px solid transparent;
    background: linear-gradient(65deg, var(--primary-color), var(--secondary-color), var(--highlight-color), var(--error-color) 40%, var(--accent-color), var(--primary-color) 60%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none;
    z-index: 1;
}
#debug-heading{
    position: relative;
    top: 30px;
    left: 220px;
    margin: 0;
    padding: 0;
    font-family: Outfit;
    font-weight: normal;
}
#debug-close{
    position: relative;
    top: -25px;
    left: 570px;
    cursor: pointer;
}
#devices{
    position: relative;
    top: 30px;
    left: 40px;
    font-size: small;
    overflow-y: scroll;
    width: 89%;
    height: 50%;
}
#devices::-webkit-scrollbar {
    width: 5px; 
}
  
#devices::-webkit-scrollbar-track {
    background-color: transparent; 
}
  
#devices::-webkit-scrollbar-thumb {
    background-color: var(--highlight-color);
    border-radius: 5px; 
}
  
#devices::-webkit-scrollbar-thumb:hover {
    background-color: var(--highlight-color);
}

#devices tr > td:nth-child(1) {
    width: 160px;
}

#devices tr > td:nth-child(2) {
    padding-left: 50px;
    width: 370px;
}
.debug-btn{
    padding: 0;
    border: none;
    background-color: transparent;
    margin: 0;
    color: white;
    cursor: pointer;
}
#debug-send-btn{
    position:relative;
    top: -200px;
    left: 450px;
    width: 110px;
    height: 50px;
    background-color: rgba(34, 36, 48, 0.7);
    border: none;
    cursor: pointer;
    background-color: transparent; 
    color: var(--highlight-color);
}
