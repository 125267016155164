:root {
  --primary-color: #1d4e76; 
  --secondary-color: #61479e; 
  --accent-color: #fbfbfb; 
  --highlight-color: #2e2532; 
  --error-color: #201a23; 
  --background-avatar: url(/public/images/trails.jpg);
  --text-color: white;
}
@font-face {
  font-family: 'Ode Idle'; 
  src: url('/public/fonts/Ode\ to\ Idle\ Gaming.otf') format('truetype');  
  font-weight: normal;
  font-style: normal;
}
.heading{
  font-family: 'Ode Idle'; 
  font-size: 30px;
}
h1{
  margin: 0;
}
body{
  height: 100%;
  min-height: 100vh;
  background-color: black !important;
  overflow-x: hidden;
}

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  background-color: black;
}

.headings{ 
  color: var(--text-color);
}

.app{
  margin: 0;
  padding: 0;
  background-position: center; 
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
}

.ticketPage{
  margin: 0;
  padding: 0;
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  position: fixed; 
  height: 100vh;
  width: 100vw;
}

.css-19kzrtu{
  padding: 0 !important;
}

.logo-logged-in{
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  margin-top: 2%;
}

a{
  text-decoration: none;
  color: white;
}

img {
  max-width: 100%; 
  max-height: 100%; 
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: var(--primary-color);
}

.header{
  text-align: center;
  color: white;
  margin: 0;
}
.welcome{
  background-image: url(/public/images/robot.jpg);
  background-attachment: fixed;
  background-size:cover ;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}
.loader{
  margin: 5%;
  text-align: center;
  font-size: large;
}

.unity{
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: white;
}


.controlBtns{
  position: absolute;
  bottom:1%;
  right: 4%;
  z-index: 250000;
}

.unityBtn{
  padding: 35%;
  background-color: var(--highlight-color);
  color: var(--primary-color) ;
  border: var(--primary-color) 1px solid;
  border-radius: 50%;
  margin: 10px;
  display: flex; 
  justify-content: center; 
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom:1%;
  right: 2%;
  z-index: 250000;
}


ul{
  list-style-type: none;
}
.css-1x5jdmq{
  color: white !important;
}

#name-input{
  color: black !important;
}

.avatar-container{
  padding-top: 300px;  
  display: flex;
}

.avatar-card{
  background-color: #bae1ff;
  padding: 100px;
  width: 30%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1;
}
.footer{
  position: absolute;
  bottom: 0;
  width: 100%;
}

.welcome-card{
  position: absolute;
  top: 30%;
  left: 40%;
}

.next{
  position: absolute;
  bottom: 0;
  right: 0;
  height: 20%;
  width: 20%;
}

.button-container{
  position: absolute;
  top: 30%;
  left: 40%;
}

.home{
  position: absolute;
  background: linear-gradient(var(--highlight-color), var(--primary-color));
  top: 0;
  left: 0;
  height: 10%;
  width: 10%;
  border-radius: 50%;
  cursor: pointer;
}

.avatar-background{
  height: 100vh;
  width: 100vw;
  margin: 0;
  background-image: var(--background-avatar);
  background-size: cover;
}
.avatar-input-area2{
  position: absolute;
  top: 3%;
  left: 6%;
  font-size: 2vh;
  color: white;
  z-index: 25000;
  padding: 50px;
  border-radius: 20px;
}
.avatar-input-area1{
  position: absolute;
  top: 8%;
  left: 70%;
  font-size: 2vh;
  color: white;
  z-index: 25000;
}

.avatar-display{
  position: absolute;
  bottom: 0;
  height: 100vh;
  width: 100%;
}
.colour-btn {
  align-items: center;
  background-color: initial;
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px var(--highlight-color),0 3px 6px var(--highlight-color);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 18px;
  height: 52px;
  justify-content: center;
  line-height: 1;
  margin: 15px;
  outline: none;
  overflow: hidden;
  padding: 0 32px;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: white;
  background: var(--primary-color);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: large;
}

.button-85:before,
.next::before {
  content: "";
  background: linear-gradient(
    45deg,
    var(--primary-color),
    var(--secondary-color),
    var(--accent-color),
    var(--highlight-color),
    var(--error-color)
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.button-84 {
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(var(--highlight-color), var(--primary-color));
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px var(--highlight-color),0 3px 6px var(--highlight-color);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 18px;
  height: 52px;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0 32px;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-84:hover {
  box-shadow: var(--error-color) 0 2px 8px;
  opacity: .85;
}

.button-84:active {
  outline: 0;
}

.button-84:focus {
  box-shadow: var(--error-color)  0 0 0 3px;
}

@media (max-width: 420px) {
  .button-84 {
    height: 48px;
  }
}

.custom-input {
  padding:1rem 1rem;
  border: double 2px transparent;
  border-radius: 50px;
  background-image: linear-gradient(var(--error-color) , var(--highlight-color) ), 
  linear-gradient(90deg, var(--primary-color) 0%, var(--accent-color) 55%, var(--secondary-color) 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: var(--highlight-color);
  color: white;
  margin-bottom: 20px;
  width: 100%;
  font-size: large;
}

.custom-input::placeholder {
  color: var(--accent-color);
}

.loader-container {
  background-color: black;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-avatar {
  height: 75vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 25000;
}

.loader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  margin: 20px 0;
  box-sizing: border-box;
  width: 10%;
  height: 10%;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.1);
  transform-origin: 50% 50%;
  transform: perspective(200px) rotateX(66deg);
  animation: spinner-wiggle 1.2s infinite;
}

.loader-content p {
  margin-top: 10px;
  text-align: center;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 4px solid #0000;
  animation: spinner-spin 1.2s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
    spinner-fade 1.2s linear infinite;
}
.loader:before {
  border-top-color: #fff;
}
.loader:after {
  border-top-color: #ff3d00;
  animation-delay: 0.4s;
}

@keyframes spinner-spin {
  100% { transform: rotate(360deg)}
}
@keyframes spinner-fade {
  25%, 75% { opacity: 0.1}
  50% { opacity: 1 }
}
    

@keyframes circle-clockwise-spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .shape1{
    animation:  circle-clockwise-spin infinite 25s linear;
  }
}

::-webkit-scrollbar{
  background-color: grey;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgb(100, 234, 255), 0 0 10px rgb(100, 234, 255), 0 0 15px rgb(100, 234, 255), 0 0 20px rgb(100, 234, 255),
                inset 0 0 2px rgb(100, 234, 255), inset 0 0 4px rgb(100, 234, 255), inset 0 0 6px rgb(100, 234, 255), inset 0 0 8px rgb(100, 234, 255);
  }
  50% {
    box-shadow: 0 0 20px rgb(100, 234, 255), 0 0 30px rgb(100, 234, 255), 0 0 40px rgb(100, 234, 255), 0 0 50px rgb(100, 234, 255),
                inset 0 0 10px rgb(100, 234, 255), inset 0 0 15px rgb(100, 234, 255), inset 0 0 20px rgb(100, 234, 255), inset 0 0 25px rgb(100, 234, 255);
  }
  100% {
    box-shadow: 0 0 0px rgb(100, 234, 255), 0 0 0px rgb(100, 234, 255), 0 0 0px rgb(100, 234, 255), 0 0 0px rgb(100, 234, 255),
                inset 0 0 0px rgb(100, 234, 255), inset 0 0 0px rgb(100, 234, 255), inset 0 0 0px rgb(100, 234, 255), inset 0 0 0px rgb(100, 234, 255);
  }
}
.prompt {
  animation: glow 2s forwards;
}

.prompt::before {
  content: '';
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  opacity: 0.5;
  z-index: -1;
}

.profile-inner-image-container{
  position: absolute;
  top: -2px;
  left: -2px; 
  width: calc(99% );
  height: calc(99% );
  border-radius: 50%;
  overflow: hidden;
  border: 2px var(--gradient-color) solid;
}

.profile-image{
    display: block;
    width: 100%; 
    height: auto; 
    object-fit: cover; 
    object-position: center top; 
    transform: scale(2) translateY(20%); 
}
.profile-modal::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 25px;
  border: 2px solid transparent;
  background: linear-gradient(65deg, var(--primary-color), var(--secondary-color), var(--highlight-color), var(--error-color) 40%, var(--accent-color), var(--primary-color) 60%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
  z-index: 1;
}

.profile-modal{
  border-radius: 25px;
  background-color: rgba(34, 36, 48, 0.7);
  height: 60%;
}

.close-button-rpm{
  padding: 0 5px;
  font-weight: bolder;
  border: none;
  background-color: transparent;
  cursor: pointer;

}
.custom-input-profile {
  position: relative;
  padding: 0.1rem 0.5rem;
  border: double 2px transparent;
  border-radius: 50px;
  background-image: linear-gradient(rgba(0,19,53,1), rgba(0,19,53,1)), 
  linear-gradient(90deg, rgba(205,224,250,1) 0%, rgba(34,80,229,1) 55%, rgba(117,6,222,1) 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: rgba(0,19,53,1);
  color: white;
}

.custom-input-profile::placeholder {
  color: rgb(66, 105, 233);
}

.faq{
  width: 70%;
  margin: auto auto; 
}

.about-footer{
  position: absolute;
  bottom: 0;
}

.faq-footer{
  position: fixed !important;
  bottom: 0;
}
hr {
  width: 100%;
  height: 1px;
  background-color: gray;
  border: none;
  margin: 0;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  border: none;
}